<template>
  <div class="logo">
    <img alt="MegaMek Unit List Logo" src="./assets/logo.png" width="128" height="128">
    <h1>MegaMek Unit List</h1>
  </div>
  <Suspense>
    <template #default>
      <battle-value />
    </template>
    <template #fallback>
      <div>Loading...</div>
    </template>
  </Suspense>
  <footer>
    <p>
      All data from the <a href="https://github.com/MegaMek" target="_blank">MegaMek Project</a>.<br />
      Record sheets generated by <a href="https://github.com/MegaMek/megameklab" target="_blank">MegaMekLab</a>.<br />
      <a href="https://icons8.com/line-awesome" target="_blank">Icon fonts from Icons8</a>
    </p>
  </footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BattleValue from './components/BattleValue.vue';

@Options({
  name: 'App',
  components: {
    BattleValue,
  },
})
export default class App extends Vue {}
</script>

<style>
@import 'assets/line-awesome-1.3.0/css/line-awesome.min.css';
@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');
#app {
  font-family: Jura, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
select, option {
  font-family: Jura, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}
.logo {
  text-align: center;
}
footer {
  font-style: italic;
  text-align: center;
  font-size: 80%;
}
</style>
