<template>
  <div class="battle-value">
    <div class="actions">
      <div class="category-selector">
        <label for="category">Catgeory:</label>
        <select v-model="category" name="category" id="category">
          <option v-for="category in categories" :key="category.path" :value="category.path">
            {{ category.name }}
          </option>
        </select>
        <label for="source">Source:</label>
        <select v-model="source" name="source" id="source">
          <option v-for="source in sources" :key="source" :value="source">
            {{ source.replace('/', ' - ') }}
          </option>
        </select>
      </div>
    </div>
    <table id="battle-value-table">
        <caption>
          This table contains a description of units and their battle value.
          Links are provided to the unit's record sheet.
        </caption>
        <thead>
            <th scope="col" title="Select for Comparison" class="align-center"></th>
            <th scope="col">Unit</th>
            <th scope="col">Source</th>
            <th scope="col" title="BattleValue" class="align-center">BV</th>
            <th scope="col" title="Tonnage" class="align-center">Tons</th>
            <th scope="col" title="Walk/Jump" class="align-center">WW/JJ</th>
            <th scope="col" title="Actions" class="align-center"></th>
        </thead>
        <tbody>
          <tr v-for="unit in filteredUnits" :key="unit.id">
              <td class="align-center">
              </td>
              <td>{{ unit.name }}</td>
              <td>{{ unit.source }}</td>
              <td class="align-center">{{ unit.bv }}</td>
              <td class="align-center">{{ unit.tonnage }}</td>
              <td class="align-center">{{ unit.walkMP }}/{{ unit.jumpMP }}</td>
              <td class="align-center">
                <a :href="link(unit.id)" title="Download Record Sheet" target="_blank"><i class="las la-file-pdf" aria-hidden="true"></i></a>
              </td>
          </tr>
        </tbody>
        <tfoot v-if="hasMoreUnits">
          <button @click="loadMoreUnits()" v-if="!loading">Load More</button>
        </tfoot>
    </table>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { defineComponent, ref, onMounted, computed,  } from 'vue'
//import VueFuse from 'vue-fuse'
// eslint-disable-next-line no-unused-vars
import type { MMUnit } from '../interfaces/MMUnit';
// eslint-disable-next-line no-unused-vars
import type { Category } from '../interfaces/Category';

import { useCategories, DEFAULT_CATEGORY } from '../services/categories';

export default {
  props: {
  },
  components: {
    //VueFuse,
  },
  async setup() {
    const { categories, category, sources, getCategories, selectCategory } = useCategories();

    const BASE_URL = 'https://10bp71c8yk.execute-api.us-east-1.amazonaws.com/Prod'

    let fetchCache : any = {};
    let srcCache : any = {};

    async function fetchUnits(cat : string, src : string, next : string | null) {
      let url = `${BASE_URL}/categories/${cat}/units/${src}${next ? `?next=${next}` : ''}`;
      if (url in fetchCache) {
        return fetchCache[url];
      }
      let res = await fetch(url);
      return fetchCache[url] = res.json();
    }

    function link(to: string) {
      return `rs/${to}.pdf`;
    }

    const loading = ref(false);
    const source = ref<string | null>(null);
    const units = ref<Array<MMUnit>>([]);
    const nextUnit = ref<string | null>(null);

    async function loadUnits(cat: string, src: string, next : string | null) {
      let unitsJson = await fetchUnits(cat, src, next);
      let mappedUnits = unitsJson.units.map((u: { path: string; details: { name: string; bv: number; tonnage: number; walkMP: number; jumpMP: number; }; }) => {
        return {
          id: u.path,
          category: cat,
          source: src,
          name: u.details.name,
          bv: u.details.bv,
          tonnage: u.details.tonnage,
          walkMP: u.details.walkMP,
          jumpMP: u.details.jumpMP,
        };
      });

      if (next) {
        units.value = units.value.concat(mappedUnits);
      } else {
        units.value = mappedUnits;
      }

      nextUnit.value = unitsJson.next || null;
    }

    async function loadMoreUnits() {
      loading.value = true;

      let cat = category.value;
      let src = source.value;
      let next = nextUnit.value;

      if (cat && src) {
        if (next === null && cat in srcCache && src in srcCache[cat]) {
          units.value = srcCache[cat][src].units;
          nextUnit.value = srcCache[cat][src].next;
        } else {
          await loadUnits(cat, src, next);

          if (!(cat in srcCache)) {
            srcCache[cat] = {};
          }
          srcCache[cat][src] = {
            units: units.value,
            next: nextUnit.value,
          };
        }
      }

      loading.value = false;
    }

    async function changeCategory(path : string) {
      units.value = [];
      nextUnit.value = null;
      selectCategory(path);
      if (category.value && sources.value) {
        await selectSource(sources.value[0]);
      }
    }

    async function selectSource(path : string) {
      source.value = path;
      nextUnit.value = null;

      await loadMoreUnits();
    }

    await getCategories();

    await changeCategory(DEFAULT_CATEGORY);

    return { 
      categories: computed(() => categories.value),
      category: computed({ 
        get() { return category.value; },
        set(value : string) {
          changeCategory(value);
        }
      }),
      sources: computed(() => sources.value),
      source: computed({
        get() { return source.value; },
        set(value : string | null) { if (value) { selectSource(value); } }
      }),
      filteredUnits: computed(() => units.value),
      hasMoreUnits: computed(() => !!(nextUnit.value)),
      link,
      loadMoreUnits,
      loading,
    };
  }
};

</script>

<style scoped>
h1 {
  text-align: center;
}
#battle-value-table {
  width: 100%;
}
label {
  padding: 4px;
}
table > caption {
  font-style: italic;
  font-size: 90%;
  padding: 4px;
}
td.align-left, th.align-left {
  text-align: left;
}
td.align-center, th.align-center {
  text-align: center;
}
.actions {
  position: -webkit-sticky;
  position: sticky;
  top: 8px;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  background: rgba(255, 255, 255, 0.8);
}
.category-selector {
  align-self: flex-start;
}
.search {
  align-self: flex-end;
}
.bg-green {
  border: 1px solid black;
  background: green;
  color: white;
}
button.bg-green:hover {
  border: 1px solid black;
  background-color: lightseagreen;
  color: #ffffff;
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
</style>