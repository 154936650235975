import { Category } from '@/interfaces/Category';
import { computed, ref } from 'vue'

export const DEFAULT_CATEGORY = 'mechs';
const BASE_URL = 'https://10bp71c8yk.execute-api.us-east-1.amazonaws.com/Prod'

async function fetchCategories() {
    let res = await fetch(`${BASE_URL}/categories`);
    return res.json();
}

export function useCategories() {
    const loading = ref(false);
    const categories = ref<Array<Category>>([]);
    const category = ref(DEFAULT_CATEGORY);
    const sources = ref<Array<string>>([]);

    async function getCategories() {
        loading.value = true;

        categories.value = await fetchCategories();

        loading.value = false;
    }

    function selectCategory(path : string) {
        loading.value = true;

        let foundCategory = categories.value.find(c => c.path === path);
        if (foundCategory) {
            category.value = path;
            sources.value = foundCategory.sources;
        }

        loading.value = false;
    }

    return {
        loading: computed(() => loading.value),
        categories: computed(() => categories.value),
        category: computed(() => category.value),
        sources: computed(() => sources.value),
        getCategories,
        selectCategory
    }
}
