
// eslint-disable-next-line no-unused-vars
import { defineComponent, ref, onMounted, computed,  } from 'vue'
//import VueFuse from 'vue-fuse'
// eslint-disable-next-line no-unused-vars
import type { MMUnit } from '../interfaces/MMUnit';
// eslint-disable-next-line no-unused-vars
import type { Category } from '../interfaces/Category';

import { useCategories, DEFAULT_CATEGORY } from '../services/categories';

export default {
  props: {
  },
  components: {
    //VueFuse,
  },
  async setup() {
    const { categories, category, sources, getCategories, selectCategory } = useCategories();

    const BASE_URL = 'https://10bp71c8yk.execute-api.us-east-1.amazonaws.com/Prod'

    let fetchCache : any = {};
    let srcCache : any = {};

    async function fetchUnits(cat : string, src : string, next : string | null) {
      let url = `${BASE_URL}/categories/${cat}/units/${src}${next ? `?next=${next}` : ''}`;
      if (url in fetchCache) {
        return fetchCache[url];
      }
      let res = await fetch(url);
      return fetchCache[url] = res.json();
    }

    function link(to: string) {
      return `rs/${to}.pdf`;
    }

    const loading = ref(false);
    const source = ref<string | null>(null);
    const units = ref<Array<MMUnit>>([]);
    const nextUnit = ref<string | null>(null);

    async function loadUnits(cat: string, src: string, next : string | null) {
      let unitsJson = await fetchUnits(cat, src, next);
      let mappedUnits = unitsJson.units.map((u: { path: string; details: { name: string; bv: number; tonnage: number; walkMP: number; jumpMP: number; }; }) => {
        return {
          id: u.path,
          category: cat,
          source: src,
          name: u.details.name,
          bv: u.details.bv,
          tonnage: u.details.tonnage,
          walkMP: u.details.walkMP,
          jumpMP: u.details.jumpMP,
        };
      });

      if (next) {
        units.value = units.value.concat(mappedUnits);
      } else {
        units.value = mappedUnits;
      }

      nextUnit.value = unitsJson.next || null;
    }

    async function loadMoreUnits() {
      loading.value = true;

      let cat = category.value;
      let src = source.value;
      let next = nextUnit.value;

      if (cat && src) {
        if (next === null && cat in srcCache && src in srcCache[cat]) {
          units.value = srcCache[cat][src].units;
          nextUnit.value = srcCache[cat][src].next;
        } else {
          await loadUnits(cat, src, next);

          if (!(cat in srcCache)) {
            srcCache[cat] = {};
          }
          srcCache[cat][src] = {
            units: units.value,
            next: nextUnit.value,
          };
        }
      }

      loading.value = false;
    }

    async function changeCategory(path : string) {
      units.value = [];
      nextUnit.value = null;
      selectCategory(path);
      if (category.value && sources.value) {
        await selectSource(sources.value[0]);
      }
    }

    async function selectSource(path : string) {
      source.value = path;
      nextUnit.value = null;

      await loadMoreUnits();
    }

    await getCategories();

    await changeCategory(DEFAULT_CATEGORY);

    return { 
      categories: computed(() => categories.value),
      category: computed({ 
        get() { return category.value; },
        set(value : string) {
          changeCategory(value);
        }
      }),
      sources: computed(() => sources.value),
      source: computed({
        get() { return source.value; },
        set(value : string | null) { if (value) { selectSource(value); } }
      }),
      filteredUnits: computed(() => units.value),
      hasMoreUnits: computed(() => !!(nextUnit.value)),
      link,
      loadMoreUnits,
      loading,
    };
  }
};

