
import { Options, Vue } from 'vue-class-component';
import BattleValue from './components/BattleValue.vue';

@Options({
  name: 'App',
  components: {
    BattleValue,
  },
})
export default class App extends Vue {}
